@font-face {
  font-family: "Norwester";
  src:url("/assets/fonts/Norwester-Regular.eot");
  src:url("/assets/fonts/Norwester-Regular.eot#iefix") format("embedded-opentype"),
      url("/assets/fonts/Norwester-Regular.ttf") format("truetype"),
      url("/assets/fonts/Norwester-Regular.woff") format("woff"),
      url("/assets/fonts/Norwester-Regular.svg#Norwester") format("svg");
  font-weight: 700;
  font-style: normal;
} 
