.blog-body {

  text-align: justify;

  h1, h2, h3, h4, h5, h6 {
    text-align: left;
  }

  ol li p {
    font-size: 18px;
    padding-left: 10px;
  }

  .smaller {
    font-size: 14px;
  }

  blockquote {
    font-size: 120%;
    font-style: italic;
  }

  figure {
    margin-bottom: 2em;
    margin-top: 2em;

    img {
      width: 100%;
    }

    figcaption {
      color: #666666;
      margin-left: 2em;
      margin-right: 2em;
      text-align: center;
    }
  }

}


.blog-end {
  font-style: italic;
  font-weight: bold;
}


.blog-archive {

  ul {
    list-style: none;
    margin-top: 2em;
    padding-left: 0;

    li {
      display: block;
      text-align: left;
    }
  }

}

.entry-byline {
  color: #888888;
  font-size: 16px;
}

.entry-date {
  font-weight: 300;
  font-style: italic;
}

.blog-block-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 2em;
}

.blog-block {
  a {
    color: $text-color;

    &:hover {
      color: $text-color;
      text-decoration: none;
    }
  }
}

.blog-block-image {
  background-size: auto 100%;
  width: 100%;
}

.blog-block-description {
  margin: 1ex;

}

.blog-block-title {
  font-size: 20px;
  font-weight: 700;
}

.blog-block-primary {
  background-color: #eeffff;
  margin: 0;
  max-width: 100%;
  min-width: 100%;

  .blog-block-link {
    display: block;
    position: relative;
  }

  .blog-block-image {
    height: 540px;
  }

  .blog-block-description {
    color: #fff;
    position: absolute;
    text-align: right;
    left: 0;
    top: 2em;
    padding-right: 4em;
    width: 100%
  }

  .blog-block-title {
    font-size: 32px;
  }

  .blog-block-excerpt {
    font-size: 20px;
    margin-top: 1em;
    padding-left: 50%;
  }

  .blog-block-author {
    font-size: 20px;
    margin-top: 1em;
    padding-left: 50%;
    font-weight: 400;
  }
}

.blog-block-secondary {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 3em;
  max-width: 360px;
  min-width: 360px;

  .blog-block-link {
    display: block;
  }

  .blog-block-image {
    height: 240px;
  }

  .blog-block-author {
    font-size: 16px;
    font-weight: 300;
    margin-top: 1ex;
  }  
}

// Share Bar */
.share-bar {
  font-size: 20px;
  margin-bottom: 20px;
}

// Title */
.share-bar h4 {
  margin-bottom: 10px;
  font-weight: 500;
}

// Each button */
.share-button {
  margin: 0px;
  margin-bottom: 10px;
  margin-right: 3px;
  border: 1px solid #D3D6D2;
  padding: 5px 10px 5px 10px;
  display: inline-block;
}

.share-button:hover {
  opacity: 1;
  color: #ffffff;
}

.share-buttons a:hover, .share-buttons a:focus {
  text-decoration: none;
}

.share-button-icon {
  display: block;
  line-height: normal;
  text-align: center;
  width: 1em;
}

// Facebook button */
.fa-facebook-official {
  color: #3b5998;
}

.fa-facebook-official:hover {
  background-color: #3b5998;
}

// Twitter button */
.fa-twitter {
  color: #55acee;
}

.fa-twitter:hover {
  background-color: #55acee;
}

// LinkedIn button */
.fa-linkedin {
  color: #007bb5;
}

.fa-linkedin:hover {
  background-color: #007bb5;
}

// Email button */
.fa-envelope {
  color: #444444;
}

.fa-envelope:hover {
  background-color: #444444;
}

.entry-content {
  font-weight: 400;
  color: #777;
}