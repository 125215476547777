@import 'bootstrap-custom-variables';
@import '_bootstrap-sprockets';
@import '_bootstrap';

@import 'ionicons-variables';
@import 'ionicons-font';
@import 'ionicons-icons';

@import 'cooper-hewitt-font';
@import 'norwester-font';

@import 'variables';
@import 'mixins';

// Specific components
@import 'turalt-navigation';
@import 'turalt-wizard';
@import 'turalt-buttons';
@import 'turalt-blog';
@import 'turalt-home';
@import 'turalt-video';
@import 'turalt-portal';
@import 'turalt-products';
@import 'turalt-sections';

@import 'credit-cards';

// Global Components
html,
body {
  height: 100%;
  width: 100%;
}

body {
  @include serif-font;
}

.page-body {
  padding-top: $navbar-height;
}

hr {
  border-color: $hr-color;
  border-width: 3px;
}

hr.light {
  border-color: white;
}

a {
  color: $link-color;
  &:hover,
  &:focus {
    color: darken($link-color, 10%);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include sans-serif-font;
  font-weight: 600;
  line-height: 1.2;
}

h1, h2 {
  font-weight: 600;
}
  
h1 {
  color: $text-color-h1;
}

h2 {
  color: $text-color-h2;
  @media (max-width: 767px) {
    font-size: 24px;
    font-weight: 600;
  }
}

h3 {
  color: $text-color-h3;
  @media (max-width: 767px) {
    font-size: 20px;
    font-weight: 400;
  }
}

p, li {
  line-height: 1.4;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}

p {
  margin-bottom: 12px;
}

.first-header {
  margin-top: 0;
}

.smaller {
  font-size: 80%;
}

.alternative-italic {
  font-style: italic;
}

.text-faded {
  color: fade(white, 70%);
}

aside {
  padding: 50px 0;
}

.no-padding {
  padding: 0;
}

.form-inline .form-group {
  @media not screen and (min-width: 768px) {
    margin-bottom: 0;
  }
}

// Navigation

.page-body-section {
  padding-top: 20px;
}

// Homepage Header

header {
  .header-content {
    text-align: left;
    padding-top: 100px;
    width: 100%;

    .header-content-inner {
      h1 {
        font-family: 'Cooper Hewitt';
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 30px;
      }

      p {
        margin-top: 32px;
        margin-bottom: 32px;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
  &:after {
    content : "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    @include background-cover();
    background-position: 85% 0%;
    background-image: url(asset_path('revised-background.jpg'));
    @media (max-width: 767px) {
      background-image: none;
      display: none;
    }
  }
  @media (min-width: 768px) {
    .header-content {
      padding-top: 120px;
      .header-content-inner {
        h1 {
          font-size: 56px;
        }
        p {
          font-size: 20px;
          font-weight: 400;
        }
      }
    }
  }
}


// Sections

.section-heading {
  margin-top: 0;
}

.service-box {
  max-width: 400px;
  margin: 50px auto 0;
  @media (min-width: 992px) {
    margin: 20px auto 0;
  }
  p {
    margin-bottom: 0;
  }
}

p.large {
  font-size: 18px;
}

.call-to-action {
  h2 {
    margin: 0 auto 20px;
  }
}

// Bootstrap Overrides

.no-gutter > [class*='col-'] {
  padding-right:0;
  padding-left:0;
}

.ion-large {
  font-size: 18px;
}

// Extras
// -- Highlight Color Customization
::-moz-selection {
  color: white;
  text-shadow: none;
  background: $gray-darker;
}

::selection {
  color: white;
  text-shadow: none;
  background: $gray-darker;
}

img::selection {
  color: white;
  background: transparent;
}

img::-moz-selection {
  color: white;
  background: transparent;
}

body {
  -webkit-tap-highlight-color: $gray-darker;
}

.form-inline-small .form-group {
  padding: 0;
  margin-bottom: 1ex;
  input.form-control {
    width: 100%;
  }
}

.image-thumbnail-container {
  max-width: 500px;
  margin-bottom: 1em;
}

.image-thumbnail {
  max-width: 100%;
  height: auto !important;
  width: auto\9; /* ie8 */
}

.about-description {
  margin-bottom: 1ex;
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.turalt-quote {
  quotes: "\201C" "\201D";
  border-left: none;
  position: relative;
  &:before {
    content: open-quote;
    font-weight: bold;
    font-size: 80px;
    position: absolute;
    left: -10px;
    top: -20px;
    color: #7a7a7a;
  }
  &:after {
    content: close-quote;
    font-weight: bold;
    font-size: 80px;
    position: absolute;
    vertical-align: top;
    left: 95%;
    top: -20px;
    color: #7a7a7a;
  }
}

.email-edge-logo {
  max-width: 400px;
  width: 100%;
  margin-bottom: 1em;
}

.form-note {
  margin-top: 1ex;
}

form .error {
  color: #ff0000;
}

.feature-list {
  margin-bottom: 1em;
}

.button-list button {
  display: block;
}

ul.check-marks {
  padding-left: 1em;
  margin-bottom: 0;
  li {
    list-style-type: none;
    &:before {
      content: $ionicon-var-checkmark-circled;
      font-family: $ionicons-font-family;
      margin-right: 1.5ex;
      color: $check-color;
    }
  }
}

ul.left-aligned-block {
  display: inline-block;
  text-align: left;
}

.email-word-form-container {
  text-align: left;
  h3 {
    margin-top: 0;
  }
}


.footer-note {
  margin-top: 3cm;
  margin-bottom: 12px;
  font-size: 15px;
}

.footer-note hr {
  border-color: #EECCCC;
  border-width: 1px;
  max-width: 100%;
}

.footer-section {
  margin-top: 4em;
}

.footer-item {
  text-align: center;
  margin-bottom: 10px;
  ul {
    margin-bottom: 0;
    li {
      padding-left: 0;
    }
  }
  @media only screen and (min-device-width : 768px) {
    .footer-item-left {
      text-align: left;
    }
    .footer-item-right {
      text-align: right;
    }
    .footer-item-center {
      text-align: center;
    }
  }
}

.footer-list {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
  margin-right: -5px;
}
.footer-list > li {
  display: block;
}

.about-summary, .about-section {
  text-align: left;
}

.about-summary {
  font-weight: 300;
}

.strikethrough {
  text-decoration: line-through;
}

.report-video {
  width: 375px;
  padding: 1px;
  border: 0.5px solid black;
}

.question-body {
  max-height: 50ex;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}
.question-wrapper.closed .question-body {
  max-height: 0px;
}

.question-wrapper h3 {
  margin-bottom: 0px;
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: #f8f8f8;
  cursor: pointer;
}
.contact-form {
  margin: 2em 0;
}
.help-block-closer {
  margin: 0;
}
.form-status-marker {
  display: none;
  &.in {
    display: block;
  }
}


.tag-cloud a::after {
  content: ", ";
}
.tag-cloud a:last-child::after {
  content: ".";
}

.icon-large {
  font-size: 22px;
  padding-left: 4px;
  padding-right: 4px;
}

.g-recaptcha {
  margin-bottom: 1.0ex;
}

.turalt-logo-text-main {
  font-weight: 600;
}