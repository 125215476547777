.navbar-default {
  @include sans-serif-font();

  transition-property: top;
  transition-duration: 0.5s;
  transition-delay: 0s;

  -webkit-box-shadow: 0 5px 4px -4px #999;
  -moz-box-shadow: 0 5px 4px -4px #999;
  box-shadow: 0 5px 4px -4px #999;

  .navbar-brand {
    color: $app-heading-color;
  }

  .navbar-header .navbar-brand {
    @include sans-serif-font();
    font-weight: 300;
    font-size: 18px;
  }
  .navbar-header .navbar-toggle {
    font-weight: 700;
    font-size: 12px;
    border: none;
  }
  .nav {
    > li {
      > a,
      > a:focus {
        font-weight: 500;
        font-size: 15px;
      }
    }
  }
  .navbar-collapse {
    border: none;
  }
  // @media (min-width: 768px) {
  //   background-color: $theme-primary;
  //   border: none;
  //   .navbar-header .navbar-brand {
  //     color: fade(white, 70%);
  //     &:hover,
  //     &:focus {
  //       color: white;
  //     }
  //   }
  //   .nav > li > a,
  //   .nav > li > a:focus {
  //     color: fade(white, 70%);
  //     &:hover {
  //       color: white;
  //       text-shadow: 0px 0px 15px white;
  //     }
  //   }
  // }
}

.dropdown-menu {
  background-color: $navbar-default-bg;
}

.dropdown-menu > li > a {
  font-size: 15px;
  // &:hover {
  //   background-color:darken($theme-primary, 10%);
  // }
}

.nav-up {
  top: -70px;
}