
.btn {
  text-transform: uppercase;
}

.btn.btn-rounded {
  //border-radius: 300px;
}

.btn-brand {
  @include button-variant($btn-brand-color, $btn-brand-bg, $btn-brand-border);
}

.btn-shade {
  @include button-variant($btn-shade-color, $btn-shade-bg, $btn-shade-border);
}

.btn-bordered {
  @include button-variant($btn-bordered-color, $btn-bordered-bg, $btn-bordered-border);
}

.btn-transparent {
  @include button-variant($btn-transparent-color, $btn-transparent-bg, $btn-transparent-border);
}

.btn-inverse {
  @include button-variant($btn-inverse-color, $btn-inverse-bg, $btn-inverse-border);
}

.btn-accent {
  @include button-variant($btn-accent-color, $btn-accent-bg, $btn-accent-border);
}

.btn.btn-brand {
  border-width: 2px;
}

.btn-xl {
  padding: 15px 30px;
  font-size: 18px;
  border-radius: $btn-border-radius-xlarge;
}

.button-row .btn {
  margin: 3px;
}

.btn-transparent {
  border-width: 2px;
  &:focus,
  &.focus {
    background-color: $btn-transparent-active;
  }
  &:hover {
    background-color: $btn-transparent-active;
  }
  &:active {
    background-color: $btn-transparent-active;

    &:hover,
    &:focus,
    &.focus {
      background-color: $btn-transparent-active;
    }
  }
}