.page-section {
  min-height: 102vh;
  display: flex;
  padding: 0;
  flex-direction: column;
}

.page-section-partial {
  min-height: 0;
}

.page-section-body {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: 0%;
  min-height: 70vh;
}

.page-section-footer {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  min-height: 10em;
  padding-top: 2em;
  padding-bottom: 5em;
}
