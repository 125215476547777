.video-playpause {
  background-image:url(asset_path('playbutton.svg'));
  background-repeat:no-repeat;
  width:30%;
  height:30%;
  position:absolute;
  left:0%;
  right:0%;
  top:0%;
  bottom:0%;
  margin:auto;
  background-size:contain;
  background-position: center;
  opacity: 0.5;
  .hidden {
    display: none;
  }
}

.video-container {
  position: relative;
  width: 95%;
  max-width: 480px;
}

.video-embed-frame {
  border: 2px solid #666666;
  width: 100%;

  &:focus {
    outline: none;
  }
}

.report-animated {
  padding: 1px;
  border: 0.5px solid #666666;
}

.email-edge-video-embedded {
  width: 100%;
}

.full-width-video-container {
  max-width: inherit;
}

.half-width-video-container {
  max-width: 512px;
  min-width: 350px;
}

.feedback-video-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media not screen and (min-width : 768px) {
    flex-direction: column;
  }
}

.video-description {
  text-align: left;
  max-width: 400px;
  margin-right: 2em;
}

