.tab-margin {
  margin-top: 1em;
  @media only screen and (min-width : 768px) {
    margin-top: 2em;
  }
}

.big-tab-margin {
  margin-top: 2em;
  @media only screen and (min-width : 768px) {
    margin-top: 3em;
  }
}

.form-inline-without-margins, .form-inline-without-margins .form-group {
  margin-left: 0;
  margin-right: 0;
}

.table tr.form-aligned-cells td {
  vertical-align: middle;
}

.order-title {
  text-align: center;
}

.order-addresses {
  margin-top: 1em;
}

.table .order-item td {
  padding-bottom: 1px;
}

.order-item-description {
  font-weight: 300;
}

.table .order-item-description td {
  padding-top: 0;
  border-top: none;
  font-size: 1.6ex;
}

.quantity-editor {
  width: 5em;
  .form-control, .input-group-btn > .btn {
    padding: 5px 6px;
  }
  .input-group-btn.quantity-value > .btn {
    padding: 5px 10px;
    background-color: #e8eaeb;
  }
  .input-group-btn.quantity-value.in > .btn {
    color: white;
    background-color: #5cb85c;
  }
}

.amount-replaced {
  text-decoration: line-through;
}

.amount-saved {
  color: #DD0000;
  font-weight: bold;
}

.saving {
  color: #DD0000;
}

.form-control.email-control {
  width: 12em;
}
.form-control.password-control {
  width: 8em;
}

.responsive-btn-toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .btn-group {
    flex-grow: 1;

    .btn {
      width: 100%;
    }
  }

  .responsive-btn-rigid {
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.form-inline-responsive {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  .form-group {
    flex-basis: 7em;
    flex-grow: 1;

    .btn {
      width: 100%;
    }

    .form-control {
      width: 100%;
    }

    &.responsive-size-2 {
      flex-basis: 17em;
    }

    &.responsive-button {
      flex-basis: auto;
    }
  }
}

.generate-button {
  max-width: 12em;
  white-space: inherit;
  margin: 5px;
}

.token-input {
  width: 3.5em;
  display: inline-block;
}

.codes-input {
  width: 8em;
  display: inline-block;
}

.download-button {
  padding-top: 12px;
  text-transform: none;
}
