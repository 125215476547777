// Variables

// Gray and Brand Colors for use across theme

$theme-primary:                  $app-heading-color;
$theme-accent:                   #008389;

$theme_tonal_complement:         #005E5B;
$theme_secondary_complement:     #00928D;
$theme_highlight_complement:     #00C9C2;

$gray-base:        #000;
$gray-darker:      lighten($gray-base, 13.5%); // #222
$gray-dark:        lighten($gray-base, 20%);   // #333
$gray:             lighten($gray-base, 33.5%); // #555
$gray-light:       lighten($gray-base, 46.7%); // #777
$gray-lighter:     lighten($gray-base, 93.5%); // #eee

$btn-brand-color:                $theme-primary;
$btn-brand-bg:                   #fff;
$btn-brand-border:               darken($theme-primary, 5%);

$btn-shade-color:                #fff;
$btn-shade-bg:                   #999;
$btn-shade-border:               darken($btn-shade-bg, 5%);

$btn-bordered-color:             #000;
$btn-bordered-bg:                #fff;
$btn-bordered-border:            #aaa;

$btn-transparent-color:          #fff;
$btn-transparent-bg:             transparent;
$btn-transparent-border:         #fff;
$btn-transparent-active:         fade-out(#fff, 0.8);

$btn-inverse-color:              #fff;
$btn-inverse-bg:                 $app-heading-color;
$btn-inverse-border:             darken($btn-inverse-bg, 5%);
$btn-inverse-active:             darken($btn-inverse-bg, 5%);

$btn-accent-color:               #fff;
$btn-accent-bg:                  $theme-accent;
$btn-accent-border:              darken($btn-accent-bg, 5%);
$btn-accent-active:              darken($btn-accent-bg, 5%);
