@font-face {
  font-family: "Cooper Hewitt";
  src:url("/assets/fonts/CooperHewitt-Heavy.eot");
  src:url("/assets/fonts/CooperHewitt-Heavy.eot#iefix") format("embedded-opentype"),
      url("/assets/fonts/CooperHewitt-Heavy.ttf") format("truetype"),
      url("/assets/fonts/CooperHewitt-Heavy.woff") format("woff"),
      url("/assets/fonts/CooperHewitt-Heavy.svg#CooperHewitt") format("svg");
  font-weight: 800;
  font-style: normal;
} 

@font-face {
  font-family: "Cooper Hewitt";
  src:url("/assets/fonts/CooperHewitt-Bold.eot");
  src:url("/assets/fonts/CooperHewitt-Bold.eot#iefix") format("embedded-opentype"),
      url("/assets/fonts/CooperHewitt-Bold.ttf") format("truetype"),
      url("/assets/fonts/CooperHewitt-Bold.woff") format("woff"),
      url("/assets/fonts/CooperHewitt-Bold.svg#CooperHewitt") format("svg");
  font-weight: 600;
  font-style: normal;
} 

@font-face {
  font-family: "Cooper Hewitt";
  src:url("/assets/fonts/CooperHewitt-Book.eot");
  src:url("/assets/fonts/CooperHewitt-Book.eot#iefix") format("embedded-opentype"),
      url("/assets/fonts/CooperHewitt-Book.ttf") format("truetype"),
      url("/assets/fonts/CooperHewitt-Book.woff") format("woff"),
      url("/assets/fonts/CooperHewitt-Book.svg#CooperHewitt") format("svg");
  font-weight: 300;
  font-style: normal;
} 

