.home-block {
  margin-top: 3em;
}

.quotation {
  //margin: 0 auto;
  line-height: 1.3;
  padding: 10px 0;
  quotes: "\201C""\201D""\2018""\2019";
}

.quotation br {
  content: "A" !important;
  display: block !important;
  margin-bottom: 0.5em !important;
}

.quotation::before {
  content: open-quote;
  display: inline;
  font-size: 3em;
  left: -5px;
  line-height: 0;
  position: relative;
  top: 20px;
}

.quotation::after {
  content: close-quote;
  display: inline;
  font-size: 3em;
  left: 5px;
  line-height: 0;
  position: relative;
  top: 25px;
}

.quotation-large {
  font-size: 18px;
  line-height: 1.3;
  margin-top: 4mm;
}

.quotation.quotation-large::before {
  top: 20px;
}

.quotation.quotation-large::after {
  top: 25px;
}

.weight-regular {
  font-weight: 400;
}

.weight-demi-bold {
  font-weight: 700;
}

.about-image {
  float: right;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 1em;

  img {
    border: 1pt solid $app-heading-color;
    border-radius: 80px;
    height: 160px;
    width: 160px;
  }

  @media not screen and (min-width: 768px) {
    margin-right: 0;

    img {
      border-radius: 60px;
      height: 120px;
      width: 120px;
    }
  }
}

.about-image p {
  width: 500px;
}

.about-image-inline {
  display: inline-block;
  margin-left: 6px;
  margin-right: 6px;
}

.about-image-float-left {
  float: left;
  margin-right: 1em;
}

.about-image-small {
  border: 1pt solid $app-heading-color;
  border-radius: 50px;
  height: 100px;
  width: 100px;
}

.about-image-tiny {
  border-radius: 40px;
  height: 80px;
  width: 80px;
}

.about-sections {
  margin-top: 1em;
}

.home-product-logo {
  height: 70px;
}

.continuous-section {
  padding-bottom: 6em;
  position: relative;
}

.products-items,
.pricing-items {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
  margin-top: 1em;
}

.pricing-items {
  margin-bottom: 15px;
  margin-top: 15px;

  @media (max-width: 768px) {
    flex-direction: column;

    .pricing-item-divider {
      height: 3px;
      margin: 10px auto;
      width: 40%;
    }
  }
}

.product-item {
  flex-basis: 25%;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 232px;

  @media (max-width: 1199px) {
    flex-basis: 50%;
  }

  @media (max-width: 768px) {
    flex-basis: 80%;
  }
}

.pricing-items-container {
  margin-bottom: 1em;
  margin-top: 2em;

  h2 {
    font-size: 24px;
    margin-bottom: 0;
    margin-top: 15px;
  }

  @media (max-width: 768px) {
    margin-left: -28px;
    margin-right: -28px;
  }
}

.pricing-logo {
  height: 36px;
  vertical-align: top;
  width: 36px;
}

.pricing-item {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 6px;
}

.pricing-item-divider {
  background-color: $hr-color;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 2px;
  padding: 0;
  width: 3px;
}

.pricing-title {
  color: $text-color-h3;
  font-size: 24px;
  font-weight: 700;
}

div.pricing-link-block {
  background-color: #f8f8f8;
  border: 1px solid $app-heading-color;
  border-radius: 4px;
  display: block;
  height: 100%;
  padding: 10px;
  width: 100%;

  h3 {
    color: $text-header-color;
  }
  p {
    margin-bottom: 0;
  }
}

.offset-anchor {
  content: ' ';
  display: block;
  position: relative;
  top: -67px;
  visibility: hidden;
}

.narrow-block {
  margin-left: 15%;
  margin-right: 15%;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;  
  }
}

.highlighted-placeholder-text {
  background-color: #ffffcc;
}

ul.unbulleted {
  list-style-type: none;
  padding-left: 0;
}

.footer-dmz-logo {
  height: 1.6ex;
  vertical-align: baseline;
}

.pricing-price {
  font-weight: 600;
}

.g-recaptcha > div {
  margin: 0 auto;
}