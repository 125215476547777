$app-heading-color:                #902239;

$navbar-height:                    60px;
$navbar-default-color:             #555;
$navbar-default-link-color:        #333;
$navbar-default-bg:                #fff;
$navbar-default-link-hover-color:  #333;

$btn-border-radius-base:           2px;
$btn-border-radius-large:          4px;
$btn-border-radius-xlarge:         6px;
$btn-border-radius-small:          1px;

$btn-font-weight:                  700;

$font-size-base:                   18px;
$font-size-h2:                     44px;
$font-size-h3:                     24px;

// These define the text color parts of the theme for the web interface
$text-header-color:                #511730;
$text-color-h1:                    $text-header-color;
$text-color-h2:                    $text-header-color;
$text-color-h3:                    $text-header-color;
$hr-color:                         $text-header-color;
$link-color:                       $app-heading-color;
$check-color:                      #00c9c2;
