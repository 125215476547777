$credit-card-path: "/assets/cards";
$credit-card-width: 160px;
$credit-card-height: 100px;

$small-factor: 36 / 100;
$large-factor: 1.5;

@mixin create-size-variant($width, $height){
  width: $width;
  height: $height;
  background-size: $width $height;
}

.credit-card-list {
  display: inline-block;
  vertical-align: middle;
}

.card-logo {
  display: inline-block;
  vertical-align: middle;

  @include create-size-variant($credit-card-width, $credit-card-height);

  &.small {
    @include create-size-variant($credit-card-width * $small-factor, $credit-card-height * $small-factor);
  }

  &.large {
    @include create-size-variant($credit-card-width * $large-factor, $credit-card-height * $large-factor);
  }

  &.card-logo-visa {
    background-image: url("#{$credit-card-path}/card-logo-visa.svg");
  }

  &.card-logo-mastercard {
    background-image: url("#{$credit-card-path}/card-logo-mastercard.svg");
  }

  &.card-logo-amex {
    background-image: url("#{$credit-card-path}/card-logo-amex.svg");
  }

  &.card-logo-unknown {
    background-image: url("#{$credit-card-path}/card-logo-unknown.svg");
  }
}

.card-logo-list {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;

  > .card-logo + .card-logo {
    margin-left: 6px;
  }
}
