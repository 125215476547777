.product-section {
  padding-top: 10px;
  min-height: 102vh;
  position: relative;
}

a.product-link-block {
  display: block;
  width: 100%;
  height: 100%;
  padding-right: 1em;
  padding-bottom: 2em;
  text-align: center;
  &:hover {
    text-decoration: none;
    color: black;
  }
  h3 {
    color: $text-header-color;
    font-weight: 600;
    margin-top: 10px;
  }
  p {
    margin-bottom: 0;
    font-size: $font-size-base;
    line-height: 1.3;
    color: black;
    a {
      color: $text-header-color;
    }
  }
}

.product-item {
  h3 {
    font-size: 18px;
  }
  p {
    font-size: 18px;
    font-weight: 300;
  }
}

.product-overview-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.product-overview-image {
  max-width: 300px;
  border: 0.5px solid black;
  padding: 1px;
}

.product-overview-side {
  max-width: 250px;
  text-align: center;
}

.caption {
  font-weight: 600;
}

.product-overview-image img {
  width: 100%;
}

.product-image {
  width: 100%;
  max-width: 420px;
}

.product-overview-image, .product-overview-side {
  margin: 5px 10px;
}

.product-summary {
  font-size: 18px;
  color: #666;
  margin-bottom: 5mm;
}

.product-reasons {
  margin: 2em 0;
  display: flex;

  flex-direction: column;
  flex-wrap: nowrap;

  justify-content: center;
  align-items: center;

  @media only screen and (min-width : 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.product-reason {
  margin: 0 0;
  max-width: 210px;
  padding: 0 8px;
  color: #666;
  font-weight: 600;

  @media only screen and (min-width : 1024px) {
    max-width: 300px;
  }
}

.reason-emailedge-logo {
  width: 24px;
  margin-bottom: 8px;
}

.image-placeholder {
  background-color: #eee;
  display: inline-block;
  height: 180px;
  margin: 5px;
  width: 260px;
}

.image-placeholder > h4 {
  align-self: center;
  text-align: center;
  width: 100%;
}

.pricing-values {
  display: inline-block;
  padding-left: 0;
  list-style: none;

  li {
    margin-left: 1.3em;
  }

  li::before {
    content: "• ";
    color: #aa0000;
    margin-left: -1.3em;
    width: 1.3em;
  }
}

.block-padding {
  @media only screen and (max-width : 768px) {
    margin-top: 2em;
  }
}

.responsive-division {
  margin-top: 2em;
}

.responsive-flex-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  @media only screen and (max-width : 768px) {
    flex-direction: column;
    .responsive-grid-reverse {
      flex-direction: column;
    }
    .responsive-padded-left {
      margin-left: 0;
    }
    .responsive-padded-right {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
.responsive-column {
  flex: 1 0 45%;
}
.responsive-grid-reverse {
  flex-direction: row-reverse;
  @media only screen and (max-width : 768px) {
    flex-direction: column;
  }
}

.full-width {
  width: 100%;
  max-width: 640px;
}
.medium-height {
  height: 100%;
  max-height: 384px;
}
.responsive-padded-left {
  margin-left: 2em;
}
.responsive-padded-right {
  margin-right: 2em;
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: $ionicons-font-family;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.glyphicon-chevron-left {
  &:before { 
    content: $ionicon-var-chevron-left;
  }
}
.glyphicon-chevron-right {
  &:before { 
    content: $ionicon-var-chevron-right;
  }
}

.embedded-carousel {
  max-width: 480px;
  margin-left: 0;
  margin-right: auto;
}

.carousel.transparent-carousel {
  .carousel-control {
    background-image: none;
    background-repeat: no-repeat;
    color: #cc4444;
  }
  .carousel-caption {
    color: #cc4444;
    font-weight: bold;
    text-shadow: none;
  }
}

.image-shadow {
  -webkit-filter: drop-shadow( 4px 4px 3px rgba(0,0,0,0.5));
  filter: drop-shadow( 4px 4px 3px rgba(0,0,0,0.5));
  -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=3, OffY=3, Color='#444')";
  filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=3, OffY=3, Color='#444')";
}

.report-transparent-shadowed {
  max-width: 480px;
  -webkit-filter: drop-shadow( 4px 4px 3px rgba(0,0,0,0.5));
  filter: drop-shadow( 4px 4px 3px rgba(0,0,0,0.5));
  -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=3, OffY=3, Color='#444')";
  filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=3, OffY=3, Color='#444')";
}

.jumbotron.product-jumbotron {
  background-color: inherit;

  p {
    font-weight: 300;
  }
}

.image-block {
  position: relative;
  bottom: 0%;
  left: 0%;
  min-height: 80%;
  max-height: 80%;
  min-width: 100%;

  .image-block-contents {
    padding-top: $navbar-height + 30px;
    min-height: 80vh;
    position: relative;

    .image-block-body {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: 15px;
      margin-right: 15px;
    }

    .image-block-body-upper {
      top: 90px;

      @media (max-width: $screen-xs-max) {
        top: 40%;
      }
    }

    .image-block-body-lower {
      top: 40%;

      @media (max-width: $screen-xs-max) {
        top: 60%;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    min-height: 100%;
    max-height: 100%;

    .image-block-contents {
      min-height: 100vh;
    }
  }
}

.image-block::after {
  content: "";
  background: url(asset_path('54885591-l-smaller.jpg'));
  background-size: cover;
  background-position: 55% 50%;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;

  @media (max-width: $screen-xs-max) {
    bottom: 60%;
  }
}


.fill-width {
  display: block;
}

.image-block-body-upper h2 {
  font-weight: 800;
  font-size: 40px;
  margin-top: 15px;
  margin-bottom: 15px;

  @media (max-width: $screen-sm-max) {
    font-size: 38px;
  }
}

.image-block-body-lower {

  h2, h3 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  h2 {
    font-weight: 600;
  }

  .caption-1 {
    font-size: 38px;

    @media (max-width: $screen-sm-max) {
      font-size: 24px;
    }
  }

  .caption-2 {
    font-size: 40px;
    font-weight: 800;

    @media (max-width: $screen-sm-max) {
      margin-bottom: 8px;
      font-size: 36px;
    }
  }

  .caption-3 {
    margin-bottom: 20px;
    @media (max-width: $screen-sm-max) {
      margin-top: 8px;
      font-size: 36px;
    }
  }
}

.psychometric-question-block, .psychometric-graphics-block, .psychometric-profile-block, .psychometric-testimonials-block {
  margin-top: 30px;
}

.psychometric-questions, .psychometric-statistics, .psychometric-testimonials, .profile-introduction {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 40px;
  margin-left: -15px;
  margin-right: -15px;

  @media (max-width: $screen-md-max) {
    flex-direction: column;
  }
}

.psychometric-question {
  margin: 15px;
  padding: 15px;
  flex: 1;

  border: 4px solid $app-heading-color;
  border-radius: 12px;

  .psychometric-question-title {
    h2, h3 {
      margin-top: 0;
      font-weight: 700;
    }
  }
}

.psychometric-statistic {
  margin-bottom: 3em;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  flex: 1;

  .psychometric-statistic-header {
    margin-bottom: 0;
    font-weight: 600;
  }

  .psychometric-statistic-number {
    font-family: "Norwester";
    text-transform: uppercase;
    font-size: 64px;
    font-weight: 800;
    line-height: 0.8;
    margin-top: 8px;
    margin-bottom: 8px;
    color: $app-heading-color;
  }
}

.testimonial {
  width: 15%;
  text-align: center;

  .testimonial-avatar {
    width: 100%;
  }
}

.psychometric-graphics-video {
  margin-top: 60px;
}

.psychometric-profile-block {
  background-color: hsla(345, 8%, 95%, 1);
}

.profile-introduction {
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  // margin-top: 40px;
  align-items: stretch;

  .profile-introduction-element {

    flex: 0 0 100%;

    &.report {
      min-height: 280px;
      max-width: 100%;

      @media (min-width: $screen-sm-min) {
        transform: translateX(35%);
      }
    }

    &.follower {
      text-align: right;
      padding-right: 15px;
    }
  }

  ul.profile-introduction-bullets {
    list-style-type: none;
    font-weight: 700;
    text-align: left;
    padding-inline-start: 15px;

    > li {
      padding-left: 6rem;
      background-image: url(asset_path('ios-checkmark-circle-outline.svg'));
      background-position: 0 12px;
      background-size: 5rem 5rem;
      background-repeat: no-repeat;
      padding-top: 2rem;
      padding-bottom: 1rem;
      font-size: 24px;
    }

    ul {
      font-size: 18px;
      font-weight: normal;
    }
  }
}

.profile-explanation {
  margin-bottom: 60px;
  margin-top: 60px;

  p {
    font-size: 22px;
    font-weight: 500;
  }
}

.report-page {
  position: absolute;
  -webkit-filter: drop-shadow( 4px 4px 3px rgba(0,0,0,0.5));
  filter: drop-shadow( 4px 4px 3px rgba(0,0,0,0.5));
  -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=3, OffY=3, Color='#444')";
  filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=3, OffY=3, Color='#444')";
  max-width: 200px;
}

.report-page-1 {
  left: -25px;
  transform: rotate(80deg);
}

.report-page-2 {
  left: 60px;
  transform: rotate(-5deg);
}

.report-page-3 {
  left: 100px;
  transform: rotate(5deg);
}

.report-page-4 {
  left: 140px;
  transform: rotate(15deg);
}

.btn-small-block {
  @media (max-width: $screen-sm-max) {
    width: 100%;
  }
}

.psychometric-question-body {
  text-align: justify;
}
