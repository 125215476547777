.bootstrap-wizard {
  display: block;
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%;
  margin-top: 1ex;
}

.bootstrap-wizard li {
  display: block;
  float: left;
  width: 25%;
  text-align: center;
  padding-left: 0
}

.bootstrap-wizard li:before {
  border-top: 3px solid #55606E;
  content: "";
  display: block;
  font-size: 0;
  overflow: hidden;
  position: relative;
  top: 11px;
  right: 1px;
  width: 100%;
  z-index: 1
}

.bootstrap-wizard li:first-child:before {
  left: 50%;
  max-width: 50%
}

.bootstrap-wizard li:last-child:before {
  max-width: 50%;
  width: 50%
}

.bootstrap-wizard li.complete a .step {
  background: #0aa66e;
  padding: 1px 6px;
  border: 3px solid #55606E
}

.bootstrap-wizard li div .step {
  background: #B2B5B9;
  color: #fff;
  display: inline;
  font-size: 1.6ex;
  font-weight: 700;
  line-height: 12px;
  padding: 7px 13px;
  border: 3px solid transparent;
  border-radius: 50%;
  line-height: normal;
  position: relative;
  top: -4px;
  text-align: center;
  z-index: 2;
  transition: all .1s linear 0s
}

.bootstrap-wizard li div.active .step, .bootstrap-wizard li div.active.complete .step {
  background: #AA0000;
  color: #fff;
  font-weight: 700;
  padding: 7px 13px;
  font-size: 15px;
  border-radius: 50%;
}

.bootstrap-wizard li div.complete .title, .bootstrap-wizard li div.active .title {
  color: #2B3D53;
  font-weight: bold;
}

.bootstrap-wizard li .title {
  color: #bfbfbf;
  display: block;
  margin: 0 5px;
  font-size: 1.6ex;
  line-height: 15px;
  max-width: 100%;
  position: relative;
  table-layout: fixed;
  text-align: center;
  top: 16px;
  word-wrap: break-word;
  z-index: 104
}

.wizard-actions {
  display: block;
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%
}

.wizard-actions li {
  display: inline
}

.tab-content.transparent {
  background-color: transparent
}

.btn-wizard-navigation {
  min-width: 7em;
}
